<template>
    <div class="card mt-4 p-4 mb-4">
        <div class="card-header">
            <i class="fa fa-users"></i> <span>{{ reslang.idInviteTMOC }}</span>
        </div>
        <div class="card-body p-2">
            <p>{{ reslang.idInviteText }}</p>
            <p>{{ reslang.idInviteText1 }}</p>
            <p>{{ reslang.idInviteText2}}</p>

            <hr />

            <div class="row mt-4" v-if="currentUser.invite_control == 1">
                <div class="col-md-12">
                    <div class="alert alert-info" :key="index" v-for="(colleges, index) in invitedColleges" :role="index">
                        {{ colleges }}
                        <button type="button" class="close" :data-dismiss="index" aria-label="Close" v-on:click="removedColleges(index)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" id="email" class="form-control" v-model="form.email" @input="changetoLower" placeholder="Enter your friend email">
                            <div class="input-group-btn">
                                <button v-on:click="add" style="cursor:pointer;" class="btn btn-info" type="button">
                                    <i class="fa fa-users-plus"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group text-right row">
                        <div class="col-md-6">
                            <button class="btn btn-primary btn-block" style="cursor:pointer;" v-on:click="update">
                                {{ reslang.idInvite }}
                            </button>
                        </div>
                        <div class="col-md-6">
                            <router-link :to="`/my-profile`" class="btn btn-danger btn-block">
                                {{ reslang.idCancel }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="alert alert-info">{{ reslang.idHelpInv1 }}</div>
                </div>
            </div>

            <div class="row mt-4" v-else>
                <div class="col-md-12">
                    <div class="alert alert-danger">
                        {{ reslang.idInviteControl }}
                    </div>
                </div>
            </div>
            
            <template v-if="employeeData.length > 0">
            <h6>{{ reslang.idListEmployee }}</h6>
            <br/>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>{{ reslang.idName }}</th>
                            <th>{{ reslang.idEmail }}</th>
                            <th>{{ reslang.idLastLogin }}</th>
                            <th>{{ reslang.idAction }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(emp, index) in employeeData" :key="index" :role="index">
                            <td>{{emp.name}}</td>
                            <td>{{emp.email}}</td>
                            <td>{{ emp['last_login'] }}</td>
                            <td class="text-center">
                                <router-link :to="`/my-profile?id=`+emp.MemberID" class="router">
                                    <span class="text-info">{{ reslang.idDetailProfile }}</span> 
                                </router-link>
                                |
                                <a href="javascript:;" class="router" v-on:click="deleteConnection(emp.id, index)">
                                    <span class="text-danger"><i class="fa fa-trash"></i></span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </template>
        </div>
    </div>
    
    <vue-basic-alert 
        :duration="500" 
        :closeIn="3000"
    ref="alert" />
</template>

<script>
export default {
    name: 'editperson-pages',
    data(){
        return {
            format: "yyyy-MM-dd",
            language_strings : ['idCancel','idInviteTMOC', 'idSave', 'idEmail', 'idInvite',
            'idInviteText','idInviteText1','idInviteText2','idHelpInv1','idListEmployee',
            'idName','idEmail','idRole','idSave','idDetailProfile','idAction','idLastLogin','idInviteControl'],
            reslang: [],
            form: {},
            invitedColleges: [],
            employeeData:[],
            mute: false
        }
    },
    created() {
        this.language_strings.sess = this.$storage.getStorageSync('langSessionID');

        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang = response
        }, () => {
            console.log('Got nothing for this user except bad news.')
        })
    },

    mounted() {
        this.getData()
    },

    methods: {
        changetoLower() {
            this.form.email = this.form.email.toLowerCase()
        },

        add() {
            if (typeof this.form.email !== 'undefined') {
                this.invitedColleges.push(this.form.email)
                this.form.email = ""
            }else{
                alert('Please input an email');
            }
        },

        removedColleges(index) {
            this.invitedColleges.splice(index, 1)
        },

        update() {
            console.log(this.invitedColleges);

            if (this.invitedColleges.length == 0) {
                alert('Please input an email');

                return false;
            }
            
            var person = this.$store.getters.currentUser.id

            let form = {
                data:this.invitedColleges,
                lang:this.language_strings.sess
            }
            this.form.profile = 1;
            this.axios.post("user/friend/"+person+"/invite/", form)
            .then(() => {
                this.$refs.alert.showAlert(
                    'success',
                    'Success invite colleges',
                    'Success'
                )
                
                this.getData();
            }).catch((error)=>{
                console.log(error)
            });
        },

        getData() {
            this.mute = true
            this.axios.get("user/connection/" + this.$store.getters.currentUser.id)
            .then(response => {
                this.employeeData = response.data.data.member
                this.mute = false
            }).catch((error) => {
                this.mute = false
                console.log(error)
            });
        },

        deleteConnection(id, index){
            if (confirm("Want to delete?")) {
                this.axios.delete("user/connection/"+id)
                .then(response =>{
                    this.employeeData.splice(index, 1);
                    console.log(response)
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    },

    computed: {
        currentUser(){
            return this.$store.getters.currentUser;
        }
    },
    
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
}
</script>

<style scoped>
.btn-create {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.router{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
</style>