import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import MainHelp from '../views/pages/helpsenter/Main.vue';
import HelpHome from '../views/pages/helpsenter/Home.vue';
import HelpContent from '../views/pages/helpsenter/Content.vue';

import MainProduct from '../views/pages/product/Main.vue';
import ProductHome from '../views/pages/product/Home.vue';
import ProductContent from '../views/pages/product/Content.vue';

import MainGear from '../views/pages/gear/Main.vue';
import GearHome from '../views/pages/gear/Home.vue';
import GearContent from '../views/pages/gear/Content.vue';

import MainBlog from '../views/pages/blog/Main.vue';
import BlogHome from '../views/pages/blog/Home.vue';
import BlogContent from '../views/pages/blog/Content.vue';

import CartPage from '../views/pages/cart/CartPage.vue';
import CheckoutPage from '../views/pages/cart/CheckoutPage.vue';
import ThankYouPage from '../views/pages/cart/ThankYou.vue';

import Contact from '../views/pages/Contact.vue';
import Terms from '../views/pages/Terms.vue';
import Privacy from '../views/pages/Privacy.vue';
import Bestingelser from '../views/pages/Bestingelser.vue';
import Frakt from '../views/pages/Frakt.vue';
import NotFound from '../views/pages/NotFound.vue';

import profileMain from '../views/pages/profile/Main.vue'
import profile from '../views/pages/profile/detail.vue'
import profileEdit from '../views/pages/profile/edit.vue'
import changepassword from '../views/pages/profile/changepassword.vue'
import CompanyConnection from '../views/pages/profile/connection/profileConnection.vue'
import memberConnection from '../views/pages/profile/connection/Member.vue'
import profileInvite from '../views/pages/profile/profileInvite.vue'
import profileSecurity from '../views/pages/profile/profileSecurity.vue'
import profileUpgrade from '../views/pages/profile/upgrade/profileUpgrade.vue'
import profileSubscription from '../views/pages/profile/profileSubscription.vue'
import RequestPage from '../views/pages/profile/request/list.vue'
import RequestSendPage from '../views/pages/profile/request/send_request.vue'

import RaceInformation from '../views/pages/profile/info/RaceInformation.vue'
import TheTrail from '../views/pages/profile/info/TheTrail.vue'
import Greenland from '../views/pages/profile/info/Greenland.vue'
import Pricing from '../views/pages/profile/info/Pricing.vue'

import ResetPasswordForm from '../views/pages/ResetPasswordForm.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },

    {
        path: '/bestingelser',
        name: 'Bestingelser',
        component: Bestingelser
    },

    {
        path: '/frakt',
        name: 'Frakt',
        component: Frakt
    },

    {
        path: '/ctc-bike',
        name: 'Helpsenter',
        component: HelpHome
    },

    {
        path: '/ctc-bike',
        component: MainHelp,
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: ':pagetitle',
                component: HelpContent
            },
        ]
    },

    {
        path: '/register',
        name: 'Register',
        component: ProductHome
    },

    {
        path: '/product',
        component: MainProduct,
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: ':id',
                component: ProductContent
            },
        ]
    },

    {
        path: '/gears',
        name: 'Gear',
        component: GearHome
    },

    {
        path: '/gear',
        component: MainGear,
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: ':id',
                component: GearContent
            },
        ]
    },

    
    {
        path: '/blogs',
        name: 'Blogs',
        component: BlogHome
    },

    {
        path: '/blog',
        component: MainBlog,
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: ':id',
                component: BlogContent
            },
        ]
    },
    
    {
        path: '/cart',
        name: 'CartPage',
        component: CartPage
    },
    
    {
        path: '/checkout',
        name: 'CheckoutPage',
        component: CheckoutPage
    },

    {
        path: '/thankyou',
        name: 'ThankYouPage',
        component: ThankYouPage
    },

    {
        path: '/my-profile',
        component: profileMain,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/my-profile',
                component: profile
            },
            {
                path: 'info/race-information',
                component: RaceInformation
            },
            {
                path: 'info/the-trail',
                component: TheTrail
            },
            {
                path: 'info/greenland',
                component: Greenland
            },
            {
                path: 'info/pricing',
                component: Pricing
            },
            {
                path: 'changepassword',
                component: changepassword
            },
            {
                path: 'edit',
                component: profileEdit
            },
            {
                path: 'connection',
                component: CompanyConnection
            },

            {
                path: 'connection/member/:id',
                component: memberConnection
            },

            {
                path: 'invite',
                component: profileInvite
            },
            {
                path: 'security',
                component: profileSecurity,
            },
           
            {
                path: 'upgrade',
                component: profileUpgrade
            },
            {
                path: 'subscription',
                component: profileSubscription
            },
            {
                path: 'request',
                component: RequestPage
            },
            {
                path: 'request/send-request/:id',
                component: RequestSendPage
            },

        ]
    },
    
    {
        path: '/reset-password/:token',
        component: ResetPasswordForm
    },
    
    { 
        path: '/:pathMatch(.*)*', 
        name: 'NotFound', 
        component: NotFound 
    },
]

const router = createRouter({
    mode:'history',
    history: createWebHistory(),
    routes
})

router.beforeEach((to) => {
    if(to.name == undefined){
        document.title = `Tourdeice`
    }
    else {
        document.title = `Tourdeice - ${to.name}`
    }
    
})

export default router
