<template>
    <!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">{{reslang['idCart']}}</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- Cart section start -->
    <div class="cy_cart_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="cy_cart_box woocommerce">
                        <table class="shop_table shop_table_responsive cart">
                            <thead>
                                <tr>
                                    <th class="product-thumbnail">{{reslang['idImage']}}</th>
                                    <th class="product-name">{{reslang['idPName']}}</th>
                                    <th class="product-price">{{reslang['idPrice']}}</th>
                                    <th class="product-price">{{reslang['idQty']}}</th>
                                    <th class="product-remove">{{reslang['idRemove']}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cart, index) in $store.getters.cart" :key="cart.id">
                                    <td class="product-thumbnail">
                                        <a>
                                            <img v-if="cart.images[0]" :src="cart.images[0]" alt="cart" class="img-fluid">
                                            <img v-else src="http://via.placeholder.com/68x68" alt="cart" class="img-fluid">
                                        </a>
                                    </td>
                                    <td class="product-name">
                                        {{cart.ProductName}}
                                        <template v-if="cart.size != null">
                                        <br/>
                                        {{reslang['idSize']}} : {{ cart.size.name }}
                                        </template>
                                        <template v-if="cart.color != null">
                                        <br/>
                                        {{ reslang['idColor'] }} : {{ cart.color.name }}
                                        </template>
                                    </td>
                                    <td class="product-price">{{cart.ProductCurrency}}{{ cart.Custprice }}</td>
                                    <td class="product-price"><b>{{ cart.quantity }}</b> </td>
                                    <td class="product-remove"><a style="cursor:pointer;" @click="removeProduct(index)"><i class="fa fa-trash-o" aria-hidden="true"></i></a></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="cy_cart_sidebar">
                        <div class="cart-collaterals">
                            <div class="cart_totals">
                                <h2>{{ reslang['idCartTotal'] }}</h2>
                                <table class="shop_table shop_table_responsive">
                                    <tbody>
                                        <tr class="cart-subtotal">
                                            <td>{{ reslang['idSubtotal'] }}</td>
                                            <td>{{ $store.getters.finalAmount }}</td>
                                        </tr>
                                        <!-- <tr class="cart-shipping">
                                            <td>Shipping</td>
                                            <td>$350</td>
                                        </tr> -->
                                        <tr class="cart-tax">
                                            <td>{{ reslang['idOfThisVAT'] }}</td>
                                            <td>{{ $store.getters.totalVAT }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="order-total">
                                            <th>{{reslang['idTotal']}}</th>
                                            <th>{{ $store.getters.finalAmount }}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="wc-proceed-to-checkout">
                                <!-- <button @click="test()" class="cy_button">Proceed To Checkout</button> -->
                                <router-link to="/checkout" class="cy_button">{{ reslang['idProceedToCheckout'] }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />
    </div>
</template>

<script>
    export default {
		name: 'cart-page',
		data(){
            return {
                language_strings : ['idSize', 'idColor', 'idCart', 'idImage', 'idPName', 'idPrice',
                    'idQty', 'idRemove', 'idCartTotal', 'idSubtotal', 'idOfThisVAT', 'idTotal', 'idProceedToCheckout'
                ],
                reslang: [],
            }
		},

		created() {

		},

		mounted() {
            this.mute = true

            if (!this.$storage) {
                this.$storage.setStorageSync('langSessionID', "no")
            }
            if(this.$storage.getStorageSync('langSessionID') == undefined){
                this.$storage.setStorageSync('langSessionID', "no")
            }

            this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang = response

                this.mute = false
            })
            .catch((error) => {
                console.log(error, 'Got nothing for this user except bad news.')

                this.mute = false
            })
		},

		methods: {
            removeProduct(index){
                this.$store.dispatch('removeProduct', index)
            },

            async test(){
                
                // let merchantSerialNumber = '346461'
                // let subscriptionKey = '24e9309edcc846dcba6b1925e3f7fffb'
                // let subscriptionKey2 = '1bef5902945b4f4c81bc1e766db990f4'
                // let clientId = '93af6ab6-281e-4359-874e-48ec8a0289b1'
                // let clientSecret = 'eaM8Q~mdQshIcExdR7aQ4WIkj75LV3X-2mHWxcDM'

                //PROD
                // let merchantSerialNumberPROD = '956349'
                // let subscriptionKey = 'c5b91f4c27334ab2bbba83f112fd0565'
                // let subscriptionKey2 = 'ab7eae48e65c49adb7cc8bcb34fae79c'
                // let clientId = '1e69d796-f02f-4205-b000-10ad0821382c'
                // let clientSecret = 'K_88Q~d6E.M7ztUeLgHqdvDo7m_m5XCtr4cmOatU'

                // this.$refs.alert.showAlert(
                //     'info',
                //     'Under Development',
                //     'Info'
                // )
            }
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },

        watch: {
            mute(val) {
                document.getElementById('mute').style.display = val ? "block" : "none"
            }
        },
    }
</script>