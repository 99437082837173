<template>
    <!-- The Modal Vipps-->
    <div class="modal fade" id="modalVipps">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{reslang.idPayment}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div id="select-interval">
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{reslang.idMonthly}}</label>
                                <button class="btn btn-block btn-primary" @click="intervalMethod(1)">NOK
                                    {{data_product.price_month}}
                                </button>
                            </div>
                            <div class="col-md-6">
                                <label>{{reslang.idYearly}}</label>
                                <button class="btn btn-block btn-primary" @click="intervalMethod(12)">NOK
                                    {{data_product.price_year}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="loader-vipps" style="display:none">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-center"><i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i></div>
                            </div>
                        </div>
                    </div>
                    <div id="iframe-vipps"></div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{reslang.idClose}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as $ from "jquery"

export default {
    name: 'profile_upgrade',
    data() {
        return {
            language_strings : ['idProceedPayment','idUpgraded','idYourPackage','idContactUs','idSelected',
            'idPayment','idMonthly','idYearly','idClose','idFreeText1'],
            reslang: [],
            dataProduct:[],
            detailProduct:{},
            checkoutSubmit:0,
            checkoutContact:0,
            dataPackage:{},
            data_product: {
            product_id:'',
            product_name:'',
            price_month:'',
            price_year:'',
            user_id:'',
            interval_count:'',
            resData: {},
            omoss: {},
            }
        }
    },
    
    mounted() {
        this.language_strings.sess = this.$storage.getStorageSync('langSessionID');

        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang = response
        }).catch((error) => {
            console.log(error, 'Got nothing for this user except bad news.')
        })

        this.getPackage();
        this.getProduct();
    },

    methods: {
        getdata() {
            this.mute = true
            this.axios.get("page/pricing").then((response) => {
                this.resData = response.data.data;
                this.omoss = response.data.omoss
                console.log(this.resData);
                this.mute = false
            }).catch((error) => {
                this.mute = false
                console.log(error, 'Got nothing for this user except bad news.')
            })
        },

        getProduct(){
            this.mute = true
            this.axios.get("product/getclassification/2")
            .then(response => {

                this.dataProduct = response.data.data
                
                this.mute = false
            })
            .catch((error)=>{
                console.log(error)
                this.mute = false
            });
        },

        getPackage(){
            this.axios.get("profile/get_package/"+this.currentUser.id)
            .then(response => {
                this.dataPackage = response.data
            })
            .catch((error)=>{
                console.log(error)
            });
        },

        toPayment(id,month,year,name){
            if(this.$store.getters.currentUser == undefined){
                alert("Please login first")
            }else{
                this.axios.get("subscribe/get_valid_product/"+this.$store.getters.currentUser.id+"/"+id)
                .then((response) => {
                    if(response.data.data.valid == 1){
                        console.log(response.data.data)
                        alert("Already registered")
                    }else{
                        this.data_product.product_id = id
                        this.data_product.price_month = month
                        this.data_product.price_year = year
                        this.data_product.product_name = name
                        this.data_product.user_id = this.$store.getters.currentUser.id

                        document.getElementById("iframe-vipps").style.display = "none";
                        document.getElementById("select-interval").style.display = "block";
                        document.getElementById("loader-vipps").style.display = "none";
                        document.getElementById("iframe-vipps").innerHTML = '';
                        $("#modalVipps").modal()
                    }
                })
                .catch((error)=>{
                    console.log(error)
                })
            }
        },

        intervalMethod(int){
            document.getElementById("iframe-vipps").style.display = "block";
            document.getElementById("select-interval").style.display = "none";
            
            let price = 0

            if(int == 1){
                price = this.data_product.price_month*100
            }else{
                price = this.data_product.price_year*100
            }

            let form = {
                "merchantSerialNumber": "659740",
                "currency": "NOK",
                "interval": "MONTH",
                "intervalCount": int,
                "isApp": false,
                "merchantRedirectUrl": window.location.origin+"/thank-you-sub/"+this.data_product.user_id+"/"+this.data_product.product_id,
                "merchantAgreementUrl": window.location.origin+"/my-profile/subscription",
                // "merchantRedirectUrl": "https://www.tixgym.com/my-profile/subscription",
                // "merchantAgreementUrl": "https://www.tixgym.com/my-profile/subscription",
                "price": price,
                "productDescription": "Membership Tixgym",
                "productName": this.data_product.product_name,
                "product_id": this.data_product.product_id,
                "user_id": this.data_product.user_id
            }

            document.getElementById("loader-vipps").style.display = "block";

            this.axios.post("subscription/to_payment_vipps",form)
            .then((response) => {
                console.log(response.data)
                window.location.replace(response.data.data.agreement.vippsConfirmationUrl);
                
            }).catch((error)=>{
                console.log(error)
            })
        },
        
        hubungiKami(params){
            this.detailProduct = params;
            this.checkoutContact = 1;
            this.checkoutSubmit = 2;
        }
        
    },

    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
}
</script>


<style scoped>

</style>