<template>
	
	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Reset Password</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- shop section start -->
    <div class="cy_shop_wrapper">
        <div class="container">
            <div class="row">
				<template v-if="pageNotFound">
					<div class="col-lg-8 col-md-12 offset-lg-2">
						<div class="cy_error_data">
							<h1>404</h1>
							<h2>page you are looking is not found</h2>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
							<a href="/" class="cy_button">Home Page</a>
						</div>
					</div>
				</template>

				<template v-else>
				<div class="col-lg-3 col-md-3"></div>
				<div class="col-lg-6 col-md-6">
					<div class="cy_sign_form">
						<form @submit.prevent="resetPassword()">
						<h1>Sign In</h1>
						<label><b>Email</b></label>
						<input type="email" v-model="form.email" placeholder="Email..." class="form-control" required>
						
						<label><b>Password</b></label>
						<input type="password" v-model="form.password" placeholder="Password..." class="form-control" required>
						
						<label><b>Password Confirmation</b></label>
						<input type="password" v-model="form.password_confirmation" placeholder="Password Confirmation..." class="form-control" required>

						<center ><button type="submit" class="cy_button">Update</button></center>
						</form>
					</div>
				</div>
				<div class="col-lg-3 col-md-3"></div>
				</template>
            </div>
        </div>
        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />
    </div>
</template>

<script>
export default {
	name: "reset-password",
	data() {
		return {
		token: this.$route.params.token,
		form:{
			email: "",
			password: "",
			password_confirmation: "",
		},
		message: false,
		pageNotFound: 0,
		mute: false
	}
	},
	mounted() {
		window.scrollTo(0, 0)
		
		this.getData()
	},
	methods: {
		getData() {
			this.mute = true
			
			this.axios.get('user/detail/'+this.token)
			.then((response) => {
				this.form = response.data.data;
				if(this.form == null){
					this.pageNotFound = 1;
				}
				
				this.mute = false
			}).catch((error)=>{
				console.log(error)
				this.mute = false
			});
		},

		resetPassword() {
			if (this.form.password !== this.form.password_confirmation) {
				alert('Your password is not same.')
				return false
			} else {
				this.mute = true
				this.form.verification_code = this.token;

				this.axios.post("user/reset/password", this.form)
				.then((response) => {

					if(response.data.data !== null){
						this.$refs.alert.showAlert(
							'success',
							'Success update password',
							'Success'
						)
					}else{
						this.$refs.alert.showAlert(
							'danger',
							'Failed update password',
							'Danger'
						)
					}

					this.mute = false
				})
				.catch((error)=>{
					console.log(error)
					this.mute = false
				})
			}
		}
	},
	
	watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
}
</script>