<template>

	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/blog.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;"> {{ blog.title }} </h1>
				</div>
			</div>
		</div>
	</div>

    <div class="cy_blog_wrapper cy_section_padding  cy_blog_page">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="cy_blog_box">
                        <div class="cy_blog_img" v-if="blog.thumbnail != null">
                            <center><v-lazy-image :src="base+blog.thumbnail" class="img-fluid" /></center>
                            <div class="cy_blog_overlay"></div>
                        </div>
                        <div class="cy_blog_data">
                            <ul class="cy_blog_info">
                                <li><a>by {{blog.created_by}}</a></li>
                                <li><a>{{ blog.date }}</a></li>
                                <li><a>{{ blog.keyword }}</a></li>
                            </ul>
                            <h2><a>{{blog.description}}</a></h2>
                            <div v-html="blog.content1"></div>
                        </div>
                    </div>
                    
                </div>
                <!--Sidebar Start-->
                <div class="col-lg-4 col-md-12">
                    <div class="sidebar cy_sidebar">
                        <!--Recent Post-->
                        <div class="widget widget_recent_entries">
                            <h3 class="widget-title">Recent Posts</h3>
                            <ul>
                                <li v-for="(blog,index) in latest_blogs" :key="index">
                                    <div class="recent_cmnt_img">
                                        <v-lazy-image :src="blog.thumbnail == null ? 'http://via.placeholder.com/70x70' : base+blog.thumbnail"
                                        style="object-fit: cover;height:70px;width:70px"/>
                                    </div>
                                    <div class="recent_cmnt_data">
                                        <h4><router-link :to="'/blog/'+blog.id">{{blog.title}}</router-link></h4>
                                        <span>{{blog.date}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!--Sociallinks-->
                        <div class="widget widget_social_links">
                            <h3 class="widget-title">follow us</h3>
                            <ul>
                                
                                <li v-if="company.Facebook != null">
                                    <a :href="company.Facebook" target="_blank">
                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                </li>
                                
                                <li v-if="company.Twitter != null">
                                    <a :href="company.Twitter" target="_blank">
                                        <i class="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>

                                <li v-if="company.Instagram != null">
                                    <a :href="company.Instagram" target="_blank">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>

                                <li v-if="company.Tiktok != null">
                                    <a :href="company.Tiktok" target="_blank">
                                        <svg fill="#ffffff" viewBox="0 0 512 512" width="13px" height="13px" style="margin-top:2px" id="icons" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/>
                                        </svg>
                                    </a>
                                </li>
                                
                                <li v-if="company.LinkedIn != null">
                                    <a :href="company.LinkedIn" target="_blank">
                                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>

                                <!-- <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
								<li><a href="#"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import subContent from './subContentNew.vue';

export default {
	name: 'blog-content',
	// components: { subContent},
	data() {
		return {
			blog: {
                thumbnail:'',
                title:'',
                description:'',
                keyword:'',
                content1:'',
                date:'',
                created_by:''
            },
            latest_blogs:[],
            company:{
                Facebook:'',
                Twitter:'',
                LinkedIn:'',
            },
			base: 'https://www.admin.tourdeice.bike',
			loading: true,
		}
	},
	mounted() {
		this.getdata()
        this.getLatestBlog()
        this.dataCompany()
	},
	methods: {
		async getdata() {
			
			window.scrollTo(0, 0)

            this.mute = true
			this.axios.get("blog/detail/"+this.$route.params.id)
            .then((response) => {
                this.blog = response.data.data
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
                this.$router.push({ name: 'NotFound' })
            })
		},
        
        getLatestBlog() {
			window.scrollTo(0, 0)

			this.axios.get("blog/latest")
            .then((response) => {
                this.latest_blogs = response.data.data
            })
            .catch((error) => {
                console.log(error)
            })
		},
        
        dataCompany() {
                var datares = this.$store.dispatch('getDataCompany', 1);

                datares.then((response) => {
                    this.company = response.data
                });
            },
	},
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>