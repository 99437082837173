<template>
	<div id="Leftside">
		<div class="container pt-3 pb-3">
			<center><h4>{{currentUser.name}}</h4></center>
		</div>

		<div class="btn-group-vertical mb-3 w-100" role="group" aria-label="...">
			<router-link :to="`/my-profile`" class="btn cy_button">
				{{ reslang['idProfile'] }}
			</router-link>
			<router-link :to="`/my-profile/connection`" class="btn cy_button">
				{{ reslang['idMyConnection'] }}
			</router-link>
			<router-link :to="`/my-profile/invite`" class="btn cy_button">
				{{ reslang.idInvite }} {{ reslang.idColleges }}
			</router-link>
			<router-link :to="`/my-profile/request`" class="btn cy_button">
				{{ reslang.idRequest }}
			</router-link>
			<!-- <router-link :to="`/my-profile/upgrade`" class="btn cy_button">
				{{ reslang.idUpgrade }}
			</router-link> -->
			<router-link :to="`/my-profile/subscription`" class="btn cy_button">
				{{ reslang.idSubscription }}
			</router-link>
			<router-link :to="`/my-profile/info/race-information`" class="btn cy_button">
				{{ reslang.idRaceInformation }}
			</router-link>

			<router-link :to="`/my-profile/info/the-trail`" class="btn cy_button">
				{{ reslang.idTheTrail }}
			</router-link>

			<router-link :to="`/my-profile/info/greenland`" class="btn cy_button">
				{{ reslang.idGreenland }}
			</router-link>

			<router-link :to="`/my-profile/security`" class="btn cy_button">
				{{ reslang.idSecurity }}
			</router-link>
			
			<router-link :to="`/my-profile/info/pricing`" class="btn cy_button">
				{{ reslang.idPricingInformation }}
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Leftside-component',
	data(){
		return {
			language_strings : ['idEdit','idProfile','idMyConnection','idColleges','idJointTraining',
			'idMyDashboard','idUpgrade','idSubscription','idMyActivity','idRequest','idRaceInformation',
				'idGreenland','idTheTrail', 'idSecurity','idPricingInformation'],
			reslang: []
		}
	},

	mounted() {
		this.getdata()
	},

	watch: {
		mute(val) {
			document.getElementById('mute').className = val ? "on" : "";
		}
	},
	
	methods: {
		async getdata(){
			this.mute = true
			if (this.$storage.getStorageSync('langSessionID')) {
				var langid = this.$storage.getStorageSync('langSessionID');
			} else {
				langid = 'en';
			}

			this.language_strings['sess'] = langid;
			var datalangs = this.$store.dispatch('languages', this.language_strings)

			datalangs.then(response => {
				this.reslang =  response
			})
			.catch( (error) => {
				console.log(error, 'Got nothing for this user except bad news.')
			})
		},
	},
	
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>
