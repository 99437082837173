<template>
    <div class="card mt-4 p-4 mb-4">
        <div class="card-header">
            <i class="fa fa-plug"></i> <span>{{ reslang.idMyConnection }}</span>
        </div>
        <div class="card-body p-2">
            <h6>{{ reslang.idTeamList }}</h6>
            <br/>
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>{{ reslang.idNumber }}</th>
                        <th>{{ reslang.idCompanies }} {{ reslang.idName }}</th>
                        <th>{{ reslang.idAction }}</th>
                    </tr>
                </thead> 
                <tbody>
                    <tr v-for="(conn, index) in listData" :key="index" :role="index">
                        <td v-if="conn">{{ index+1 }}</td>
                        <td v-if="conn"></td>
                        <td v-if="conn">
                            <router-link :to="'/my-profile/connection/member/'+conn.id" class="label label-warning">
                                {{ reslang.idMember }}<i class="fa fa-pencil"></i>
                            </router-link>
                        </td>
                    </tr>
                    <tr v-if="listData.length == 0">
                        <td colspan="99">{{ reslang.idNoData}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'connection_list',
    data(){
        return {
            language_strings : ['idFamily','idCoaches','idClub','idCompanies',
                'idCoaches','idCompanies','idMyConnection','idConnect','idCoach',
                'idName','idDisconnect','idAdd','idEdit','idRemove','idMember','idBack',
                'idMyConnection','idAction','idNoData','idNumber','idTeamList'],
            reslang: [],
            listData:[],
            form:{
                UserId:this.$store.getters.currentUser.id,
                ConnectionType:1,
            },
            formData:{},
            nTitle:"",
            nTipe:"",
            mute: false
        }
    },  
    
    mounted() {
        if (this.$storage.getStorageSync('langSessionID')) {
                var langid = this.$storage.getStorageSync('langSessionID');
        } else {
            langid = 'en';
        }
        this.language_strings['sess'] = langid;
            var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang =  response
        })
        .catch( (error) => {
            console.log(error, 'Got nothing for this user except bad news.')
        })
        
        this.getData();
    },

    methods: {
        getData() {
            this.mute = true
            this.axios.get("user/connection?userid="+this.$store.getters.currentUser.id+"&tipe=internal")
            .then((response) => {
                this.listData = response.data.data
                this.mute = false
            });
        },
    },

    
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
}
</script>