<template>
    <!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">{{ reslang['idCart'] }}</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- checkout section start -->
    <div class="cy_checkout_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 offset-lg-2">
                    <div class="cy_checkout_box">

                        <div class="woocommerce-billing-fields">
                            <form @submit.prevent="payment()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idName']" class="form-control" v-model="form.name" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idPhoneNumber']" class="form-control" v-model="form.phone" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" :placeholder="reslang['idEmail']" class="form-control" v-model="form.email" required>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idCountry']" class="form-control" v-model="form.country" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idPostNumber']" class="form-control" v-model="form.postal_code" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea :placeholder="reslang['idAddress']" class="form-control" v-model="form.address" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="pay_btn" class="cy_button next">{{ reslang['idProceedPayment'] }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
		name: 'cart-page',
		data(){
            return {
                language_strings : ['idCart', 'idCountry', 'idPhoneNumber', 'idEmail', 'idName',
                    'idPostNumber','idAddress', 'idProceedPayment'
                ],
                reslang: [],
                form: {}
            }
		},

		created() {

		},

		mounted() {  
            if (!this.$storage) {
                this.$storage.setStorageSync('langSessionID', "no")
            }
            if(this.$storage.getStorageSync('langSessionID') == undefined){
                this.$storage.setStorageSync('langSessionID', "no")
            }

            this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang = response

                this.mute = false
            })
            .catch((error) => {
                console.log(error, 'Got nothing for this user except bad news.')

                this.mute = false
            })
		},

		methods: {
            payment(){
                let form_data = {
                    products : this.$store.getters.cart,
                    final_amount : this.$store.getters.finalAmount,
                    total_vat : this.$store.getters.totalVAT,
                    details : this.form
                }

                let btn_pay = document.getElementById("pay_btn");
                btn_pay.disabled = true

                this.axios.post("order/create",form_data)
                .then((response) => {
                    console.log(response)
                    
                    if(response.data.code == 200){
                        window.location.replace(response.data.url)
                    }else{
                        alert("Ups, something wrong")
                    }

                    btn_pay.disabled = false
                })
                .catch((error) => {
                    console.log(error)
                    btn_pay.disabled = false
                    this.$router.push({ name: 'NotFound' })
                })
            }
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>