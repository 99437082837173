<template>
    <!--Contact section start-->
    <div class="cy_contact_wrapper">
        <div class="container">
            <div class="row pt-5">
                <div class="col-lg-12 col-md-12">
                    <center><h1 class="cy_heading">Terms of use</h1></center>
                </div>
            </div>
            <div class="row padder_top50">
                <div class="col-md-12">
                    <div class="content-helpsenter" v-html="term.content1"></div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'contact-page',
    data(){
        return{
            term: [
                {
                    content1:''
                }
            ],
            mute:false,
        }
    },
	created(){
		
    },

    mounted() {
        this.getDataNode(44, "term")
    },

    methods: {
        getDataNode(id, param) {
            this.mute = true
            let lang = this.$storage.getStorageSync('langSessionID')
            this.$store.commit('setLang', lang)
            var dataNode = this.$store.dispatch("getNodeById", id)

            dataNode
            .then(response => {
                this.mute = false
                this.$data[param] = response.data
            }).catch((error) => {
                this.mute = false
                console.log(error, 'Got Nothing for this user except bad news')
            })
            
            window.scrollTo(0, 0)
        },
    },
     watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>
