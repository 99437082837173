<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
    export default {
        name: "main-help",
        mounted() {
           window.scrollTo(0, 0)
        },
    }
</script>

<style scoped>

</style>