<template>
    <div class="card mt-4">
        <div class="card-header">
            <ul class="nav nav-tabs">
                <li class="nav-item"><router-link class="nav-link active" :to="`/my-profile`">{{ reslang.idProfile }}</router-link></li>
                <li class="nav-item"><router-link class="nav-link" :to="`/my-profile/changepassword`">{{reslang.idChangePassword }}</router-link></li>
            </ul>
        </div>
        <div class="card-body">
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td><label>{{ reslang.idName }}</label></td>
                                <td><label>:</label></td>
                                <td><label>{{ form.first_name }} {{' '+form.last_name }}</label></td>
                            </tr>
                            <tr>
                                <td><label>{{ reslang.idAge }}</label></td>
                                <td><label>:</label></td>
                                <td><label>{{ form.age }} {{ reslang.idYearsOld }}</label></td>
                            </tr>
                            <tr>
                                <td><label>{{ reslang.idEmail }}</label></td>
                                <td><label>:</label></td>
                                <td><label>{{ form.email }}</label></td>
                            </tr>
                            <tr>
                                <td><label>{{ reslang.idPhoneNumber }}</label></td>
                                <td><label>:</label></td>
                                <td><label>{{ form.no_hp }}</label></td>
                            </tr>
                            <tr>
                                <td><label>{{ reslang.idAbout }}</label></td>
                                <td><label>:</label></td>
                                <td><label>{{ form.about }}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group text-right pr-3">
                <router-link :to="`/my-profile/edit`" class="btn btn-primary"><i class="fa fa-edit"></i> {{ reslang.idEdit}}</router-link>
            </div>

          </div>
    </div>
</template>

<script>
export default {
    name: 'profiledetail-pages',
    data(){
        return {
            language_strings : ['idUser','idSuccess','idError','idMessage','idDetail',
            'idNew','idName','idEmail','idPhoneNumber','idTitle','idChange','idPassword',
            'idRepeat', 'idChangePassword', 'idNewPassword', 'idRepeatPassword', 
            'idUseTheForm','idProfile','idEdit','idAge','idYearsOld','idWeight',
            'idHeight','idBMI','idHandicap','idDataNotFound','idLastLogin','idActivity','idAbout',
            'idWriteYourBikeCV', 'idBoodyZ'],
            reslang: [],
            form: {},
            error: null,
            mute:false,
        }
    },

    mounted() {
        if (!this.$storage) {
            this.$storage.setStorageSync('langSessionID', "en")
        }

        if(this.$storage.getStorageSync('langSessionID') == undefined){
            this.$storage.setStorageSync('langSessionID', "en")
        }

        this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang = response
        })
        .catch((error) => {
            console.log(error, 'Got nothing for this user except bad news.')
        })

        var idUser = this.$store.getters.currentUser.id;

        if (this.$route.query.id) {
            idUser = this.$route.query.id;
        }

        this.getData(idUser)
    },

    methods: { 
        getData(id) {
            window.scrollTo(0, 0)
        
            this.mute = true
            this.axios.get('profile/edit/'+id)
            .then((response) => {
                this.form = response.data.data.user;
                var dob = new Date(this.form.birth_date);
                var today = new Date();
                var age = Math.floor((today-dob) / (365.25 * 24 * 60 * 60 * 1000));
                
                this.form.age = age;
                
                this.mute = false
            }).catch((error)=>{
                console.log(error)
                this.mute = false
            });
        },
    },

    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },

    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
}
</script>

<style scoped>
    .nav-tabs {
        border-bottom: none;
    }
</style>