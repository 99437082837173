<template>
    <div class="card mt-4">
        <div class="card-header">
            <ul class="nav nav-tabs">
                <li class="nav-item"><router-link class="nav-link" :to="`/my-profile`">{{ reslang.idProfile }}</router-link></li>
                <li class="nav-item"><router-link class="nav-link active" :to="`/my-profile/changepassword`">{{reslang.idChangePassword }}</router-link></li>
            </ul>
          </div>
        <div class="card-body p-4">
            <h5>{{ reslang.idChangePassword }}</h5>
            <br/>
            <form id="defaultForm" @submit.prevent="changePasswod()">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                            <input type="password" v-model="form.password" :placeholder="reslang.idNewPW" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                            <input type="password" v-model="form.repassword" :placeholder="reslang.idReTypePw" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 text-right">
                        <button type="submit" class="btn btn-primary" style="cursor:pointer" id="submitbutton">
                            <i class="fa fa-pencil-square-o"></i> {{ reslang.idChangePassword }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <vue-basic-alert 
        :duration="500" 
        :closeIn="3000"
    ref="alert" />
</template>

<style scoped>
  .nav-tabs {
    border-bottom: none;
  }
</style>

<script>
export default {
    name: 'change-password',
    data(){
        return {
            language_strings : ['idChangePassword','idProfile','idNewPW','idPasswordIsRequired','idReTypePw', 'idBoodyZ'],
            reslang: [],
            form:{
                password: '',
                repassword: '',
            },
        }
    },

    mounted() {
        this.getdata()
    },

    methods: {
        getdata(){

            if(this.$store.getters.currentUser == undefined){
                this.$router.push('/')
            }else{
                if (this.$storage.getStorageSync('langSessionID')) {
                    var langid = this.$storage.getStorageSync('langSessionID');
                } else {
                    langid = 'en';
                }

                this.language_strings['sess'] = langid;
                var datalangs = this.$store.dispatch('languages', this.language_strings)

                datalangs.then(response => {
                    this.reslang =  response
                })
                .catch( (error) => {
                    console.log(error, 'Got nothing for this user except bad news.')
                })
            }	
        },

        changePasswod(){
            let submit_btn = document.getElementById("submitbutton")
            submit_btn.disabled = true

            if (this.form.password != this.form.repassword) {
                this.$refs.alert.showAlert(
                    'error',
                    'Password validation failed',
                    'Error'
                )
                submit_btn.disabled = false
            } else {
                this.loginStatus = null
                this.form.id = this.$store.getters.currentUser.id
                this.form.email = this.$store.getters.currentUser.email

                this.axios.post('changepassword', this.form)
                .then((response) => {
                    submit_btn.disabled = false
                    this.$refs.alert.showAlert(
                        'success',
                        response.data.data,
                        'Success'
                    )

                    this.form.password = ''
                    this.form.repassword = ''
                    this.form.id = ''
                    this.form.email = ''
                }).catch((error)=>{
                    submit_btn.disabled = false
                    this.$refs.alert.showAlert(
                        'error',
                        error,
                        'Error'
                    )
                })
            }
        }
    }
}
</script>