<template>
    
	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/ctcbike.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">MORE ABOUT TOUR DE ICE</h1>
				</div>
			</div>
		</div>
	</div>

    <div class="row pt-5 pb-4" style="place-content: center;">
        <div class="container">
            <p class="h6" v-html="ctcbike.content1"></p>
        </div>
    </div>

    <hr/>

	<div class="cy_event_wrapper pt-4">
        <div class="container">
            <div class="row">
                
                <template v-for="(hs, index) in helpsenter" :key="index">
                <div class="col-lg-6 col-md-6" v-if="hs.value != 345 && hs.value != 360 && hs.value != 368 && hs.value != 67 && hs.value != 344">
                    <div class="cy_event_box">
                        
                        <div class="cy_event_data">
                            <h2><router-link :to="'/'+hs.alias">{{ hs.text }}</router-link></h2>
                            <p v-if="hs.content1 != null" v-html="hs.content1"></p>
                            <p v-if="hs.content2 != null" v-html="hs.content2"></p>
                            <p v-if="hs.content3 != null" v-html="hs.content3"></p>
                            <p v-if="hs.content4 != null" v-html="hs.content4"></p>
                            <router-link :to="'/'+hs.alias" class="cy_button">{{ reslang['idReadMore'] }}</router-link>
                        </div>
                    </div>
                </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'home-helpsenter',
    data(){
        return{
            helpsenter: [],
            mute:false,
			language_strings : ['idReadMore'],
			reslang: [],
            ctcbike:{
                content1:''
            }
        }
    },
	created(){
		
    },

    mounted() {
        window.scrollTo(0, 0)
		if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "no")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "no")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

        this.getNode(26, 'ctcbike')
        this.getDataNode(26, "helpsenter")
    },

    methods: {
        getDataNode(id, param) {
            let lang = this.$storage.getStorageSync('langSessionID')
            this.$store.commit('setLang', lang)
            var dataNode = this.$store.dispatch("getNodes", id)
            this.mute = true

            dataNode.then(response => {
                this.$data[param] = response.data
                this.mute = false
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
                this.mute = false
            })
        },

        getNode(id, param) {
            var dataNode = this.$store.dispatch("getNodeById", id)

            dataNode.then(response => {
                this.$data[param] = response.data
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
            })
        },
    },
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>