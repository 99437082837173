<template>
	<div>
		<div id="heading-pages">
			<img src="/assets/images/banner/baner.jpg" style="width:100%" alt="image profile">
		</div>
		<div class="container">
			<div class="row">
				<div class="col-md-3">
					<Leftside />
				</div>
				<div class="col-md-9">
					<div class="main-post">
						<div class="main-content">
							<router-view></router-view>
						</div>
					</div>
				</div>      
			</div>
		</div>
	</div>
</template>

<script>
import Leftside from './leftside.vue';

export default {
	name: 'profile-main',
	data(){
		return {
			language_strings : ['idEdit','idProfile','idChangePassword'],
			reslang: [],
			form: {email:'', password:''},
			error: null,
			person: this.$store.getters.currentUser.id,
			}
	},

	components: {Leftside},

	created() {
		this.language_strings.sess = this.$storage.getStorageSync('langSessionID');
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error.response)
			console.log('Got nothing for this user except bad news.')
		})
	},

	mounted() {
		window.scrollTo(0, 0);
	},

	methods: {

	},
	
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
			},
	},
}
</script>

<style scoped>
  .bg__profile {
	height: 45vh;
	border-bottom: 3px solid #154470;
  }
</style>