<template>
    <div class="card mt-4 p-4 mb-4">
        <div class="card-header">
            <i class="fa fa-thumbs-o-up"></i> <span>{{ reslang.idSubscription }}</span>
        </div>
        <div class="card-body p-2">
            <h6>Please check the lists below to recieve the informations about it.</h6>
            <br/>
            <form @submit.prevent="subscription">
                <div class="form-group">
                    <div class="checkbox form-check">
                        <label class="form-check-label">
                            <input v-model="subscribe.News" type="checkbox" true-value="1" class="form-check-input">
                            News
                        </label>
                    </div>
                    <div class="checkbox form-check">
                        <label class="form-check-label">
                            <input v-model="subscribe.Monthly_Status" type="checkbox" true-value="1" class="form-check-input">
                            Your Monthly Status
                        </label>
                    </div>
                    <div class="checkbox form-check">
                        <label class="form-check-label">
                            <input v-model="subscribe.Course_invitations" type="checkbox" true-value="1" class="form-check-input">
                            Course and other invitations
                        </label>
                    </div>
                </div>
                <div class="form-group text-right">
                    <button type="submit" class="btn btn-primary" style="cursor:pointer;"><i class="fa fa-floppy-o"></i> Save</button>
                </div>
            </form>
        </div>
    </div>

    <vue-basic-alert 
        :duration="500" 
        :closeIn="3000"
    ref="alert" />
</template>

<style scoped>
.checkbox {
    color: #31708f;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: #d9edf7;
}
.checkbox label {
    display: block;
}
  
</style>

<script>
export default {
    name: 'my-own-analyse',
    data() {
        return {
            language_strings : ['idNumber','idProductName','idUnsubscribe','idDataNotFound','idProductSubscription',
                'idDuration','idDayLeft','idTotalDays','idLastDate','idDateRegistered','idSubscription'],
            reslang: [],
            subscribe: {
                News:0,
                Monthly_Status:0,
                Course_invitations:0
            },
            productSubscribe: {},
            mute:false
        }
    },

    mounted() {
        this.language_strings.sess = this.$storage.getStorageSync('langSessionID')

        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang = response
        })
        .catch((error) => {
            console.log(error, 'Got nothing for this user except bad news.')
        })

        this.getsubscribedata();
        this.getPackage();
    },

    methods: {
        getPackage(){
            this.axios.get("profile/get_package/"+this.currentUser.id)
            .then(response => {
                this.productSubscribe = response.data;
            })
            .catch((error)=>{
                console.log(error)
            });
        },

        getsubscribedata(){
            this.mute = true
            this.axios.get('profile/subscription/'+this.currentUser.id)
            .then(response => {
                if (response.data.data) {
                    this.subscribe = response.data.data
                    console.log(this.subscribe)
                }

                this.mute = false
            })
            .catch((error)=>{
                console.log(error)
                this.mute = false
            });
        },

        subscription(){
            this.subscribe.PersonID = this.currentUser.id
            this.axios.post('profile/subscription/save', this.$data.subscribe)
            .then(response => {
                this.$refs.alert.showAlert(
                    'success',
                    'Success save data',
                    'Success'
                )

                console.log(response)
            })
            .catch((error)=>{
                console.log(error)
            });
        },

        unsubscribe(id){
            if(confirm("Are you sure?") == true) {
                let form = {
                    id:id
                }
                
                this.axios.post('profile/subscription/delete',form)
                .then(response => {
                    alert(response.data.data)
                    this.getPackage()
                })
                .catch((error)=>{
                    console.log(error)
                });
            }
        },
    },

    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
    
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
}
</script>