<template>
    <div class="card mt-4 p-4 mb-4">
        <div class="card-header">
            <i class="fa fa-bell"></i> <span>Request List</span>
        </div>
        <div class="card-body p-2" v-if="this.admin == 0 ">
            <div class="alert alert-info">
                <strong>Info!</strong> You're member of this Company.
            </div>
        </div>

        <div class="card-body p-2" v-else>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="text-center">{{ reslang.idSender}}</th>
                            <th class="text-center">{{ reslang.idReceiver}}</th>
                            <th class="text-center">{{ reslang.idCreatedDate }}</th>
                            <th class="text-center">{{ reslang.idStatus }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr @click="toChat(req.id)" style="cursor:pointer;" v-for="req in datarequest" :key="req.id" :class="backgroundrequest(req.from_PersonID)">
                            <td>{{req.from_PersonName}}</td>
                            <td>{{req.to_PersonName}}</td>
                            <td>{{req.created}}</td>
                            <td>{{req.status}} <span v-if="req.notif != 0" class="label label-primary">{{req.notif}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'requestPage',
    data(){
        return {
            mute: false,
            language_strings : ['idSender','idReceiver','idCreatedDate','idStatus',
            'idSender','idReceiver','idCreatedDate','idStatus'],
            reslang: [],
            datarequest:[],
            notifrequest:'',
            admin:0,
        }
    },

    mounted() {
        this.getdata()
    },
    
    watch: {
        mute(val) {
            document.getElementById('mute').className = val ? "on" : "";
        }
    },
    methods: {
        async getdata(){
            this.mute = true

            if(this.$store.getters.currentUser == undefined){
                this.$router.push('/')
            }

            if(this.$store.getters.currentUser.type == "B"){
                this.$router.push('/')
            }
            
            if (this.$storage.getStorageSync('langSessionID')) {
                var langid = this.$storage.getStorageSync('langSessionID');
            } else {
                langid = 'en';
            }
            this.language_strings['sess'] = langid;
                var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang =  response
            })
            .catch( (error) => {
                console.log(error, 'Got nothing for this user except bad news.')
            })

            await this.axios.get("request/list/"+this.$store.getters.currentUser.id)
            .then((response) => {
                this.admin = response.data.admin
            
                var datalangs = this.$store.dispatch('languages', this.language_strings)

                datalangs.then(response => {
                    this.reslang =  response
                })
                .catch( (error) => {
                    console.log(error, 'Got nothing for this user except bad news.')
                })
                
                this.datarequest = response.data.datarequest
                this.notifrequest = response.data.notifrequest
                
                this.mute = false
            }).catch((error)=>{
                console.log(error)
                this.mute = false
            })
        },

        backgroundrequest(id){
            if(id == this.$store.getters.currentUser.id){
                return 'success'
            }else{
                return 'danger'
            }
        },

        toChat(id){
            this.$router.push('request/send-request/'+id)
        },
    }
}
</script>