<template>
  <div>
    <div class="card card-gray card-outline rounded-0">
        <div class="card-header">
        <h3 class="card-title"><i class="fas fa-user-tag"></i> {{ reslang.idMember }}</h3>
        <div class="card-tools">
            <router-link :to="`/my-profile/connection`"><i class="fas fa-angle-double-left"></i> {{ reslang.idBack }}</router-link>
        </div>
        </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-striped table-project mb-0">
        <thead>
          <tr>
            <th>{{ reslang.idNumber }}</th>
            <th>{{ reslang.idUserName }}</th>
            <th>{{ reslang.idUserEmail }}</th>
            <th>{{ reslang.idStatus }}</th>
            <th>{{ reslang.idAction }}</th>
          </tr>
        </thead> 
        <tbody>
          <tr v-for="(conn, index) in listData" :key="index" :role="index">
            <td>1</td>
            <td>{{ conn.name }}</td>
            <td>{{ conn.email }}</td>
            <td>{{ conn.role }}</td>
            <td class="text-center">
                <button v-on:click="disconnect(conn,index)" class="btn btn-flat btn-sm btn-danger" v-if="currentUser.id == conn.user_id">
                    {{ reslang.idDisconnect }}
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'add-pages',
    data(){
      return {
        language_strings : ['idEdit','idCancel','idGender','idEmail','idPhoneNumber','idTitle',
            'idAddress', 'idSave','idMember','idBack','idNumber','idUserName','idUserEmail','idStatus',
            'idDisconnect'],
        reslang: [],
        form: {},
        listData:{},
      }
    },
    // created() {
    //     let loader = this.$loading.show({
    //         container: this.fullPage ? null : this.$refs.formContainer,
    //         canCancel: false,
    //         loader: 'bars',
    //         width: 64,
    //         height: 64,
    //         backgroundColor: '#ffffff',
    //         opacity: 0.5,
    //         zIndex: 999,
    //     });

    //     this.language_strings.sess = this.$storage.getStorageSync('langSessionID');

    //     var datalangs = this.$store.dispatch('languages', this.language_strings)

    //             datalangs.then(response => {
    //                 this.reslang =  response
    //                 loader.hide()
    //             })
    //             .catch( (error) => {
    //               loader.hide()
    //                 console.log(error, 'Got nothing for this user except bad news.')
    //             })
    // },
    mounted() {
      let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            loader: 'bars',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
        });

        this.language_strings.sess = this.$storage.getStorageSync('langSessionID');

        var datalangs = this.$store.dispatch('languages', this.language_strings)

                datalangs.then(response => {
                    this.reslang =  response
                    loader.hide()
                })
                .catch( (error) => {
                  loader.hide()
                    console.log(error, 'Got nothing for this user except bad news.')
                })
        this.getData()
    },
    methods: {
        getData() {
            window.scrollTo(0, 0)
            let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                loader: 'bars',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            });
       
            this.axios.get("user/connection/"+this.$route.params.id+"/edit")
            .then((response) => {
                this.listData = response.data.data;
                loader.hide()

            });
        },
        disconnect(param,index) {
            
            let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                loader: 'bars',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            });

           
            this.axios.post("user/disconnect", {param})
            .then((response) => {
                alert('Success')
                console.log(response)
                this.listData.splice(index, 1);
                loader.hide()
            })
            .catch((error)=>{
                console.log(error)
                loader.hide()
            })
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
  }
</script>